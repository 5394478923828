import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import Button from 'components/shared/button';
import CanvasVideo from 'components/shared/canvas-video';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import AWSPartnerIcon from 'icons/aws-partner.inline.svg';

const SelectPartner = ({ className, withContainer }) => {
  const Tag = withContainer ? Container : 'div';
  return (
    <Tag className={classNames('', className)}>
      <p className="text-center text-lg font-semibold leading-normal text-white lg:text-left lg:text-xl lg:leading-normal">
        We are AWS Select Partner
      </p>
      <AWSPartnerIcon className="mx-auto mt-5 h-auto w-20 lg:mx-0" />
    </Tag>
  );
};

SelectPartner.propTypes = {
  className: PropTypes.string,
  withContainer: PropTypes.bool,
};

SelectPartner.defaultProps = {
  className: '',
  withContainer: false,
};

const Hero = () => {
  const [sectionRef, inView] = useInView();
  const [backgroundColor, setBackgroundColor] = useState('');

  return (
    <section ref={sectionRef} className="overflow-hidden bg-black" style={{ backgroundColor }}>
      <div className="relative mx-auto flex max-w-[1600px] flex-col pb-10 lg:block lg:flex-row lg:pb-0">
        <div className="relative order-1 -mt-10 h-[375px] w-full xs:-mt-16 xs:h-[450px] sm:mx-auto sm:h-auto sm:max-w-[600px] sm:-translate-x-8 lg:absolute lg:top-[-40px] lg:-right-16 lg:max-w-[750px] xl:top-0 xl:right-0 xl:max-w-[850px] 2xl:-right-24 2xl:top-[-100px] 2xl:w-[71%] 2xl:max-w-none">
          <CanvasVideo
            label="Hero video"
            src="/videos/hero.mp4"
            className="absolute top-0 left-1/2 h-full -translate-x-1/2 sm:relative sm:left-0 sm:h-auto sm:w-full sm:translate-x-0"
            inView={inView}
            setBackgroundColor={setBackgroundColor}
          />
        </div>
        <Container size="md">
          <div className="mx-auto pt-10 text-center lg:mx-0 lg:max-w-max lg:py-24 lg:text-left xl:py-32 2xl:py-36">
            <Heading tag="h2" className="leading-[1.125] lg:max-w-[520px]">
              Architecting Cloud Native Solutions
            </Heading>
            {/* <p className="pt-5 text-lg font-normal leading-normal text-white lg:max-w-[511px] lg:text-xl lg:leading-normal">
              We mesh our expertise in leadership and technology into shaping high-performing IT
              organizations.
            </p> */}
            <div className="relative z-10 mt-8">
              <Button
                className="uppercase"
                to="mailto:hello@arch.cloud"
                size="normal"
                theme="primary"
              >
                Contact us
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};
export default Hero;
